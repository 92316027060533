import htmx from 'htmx.org';

const ext = 'package-selector';

(() => {
  htmx.defineExtension(ext, {
    onEvent: function (name, event) {
      if (name !== 'htmx:afterProcessNode') {
        return;
      }

      const el = (event.target || event.detail.elt) as HTMLElement;
      const url = el.dataset['url'] as string;
      const redirect = el.dataset['redirect'] as string;

      el.addEventListener('click', () => {
        htmx
          .ajax('POST', url, {
            swap: 'none',
            values: { packageId: el.dataset['id'] }
          })
          .then(() => {
            if (redirect) {
              window.location.href = redirect;
            }
          });
      });
    }
  });
})();
